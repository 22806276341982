import React, { FC } from "react"
import { useStaticQuery, graphql } from "gatsby";

import { DesktopStepIcon, DesktopStepText, DesktopStepTitle, DesktopStepDescription, DesktopStepsContainer, DesktopStep, DesktopStepsBlock, Title, Container, Text, StepBlock, StepsBlock, StepNumber, StepTitle, StepText, StepImage } from "../style/4-steps/4-steps-container";
import userLanguage from "../../hooks/useLanguage";

const HomeSteps: FC = () => {
  const language = userLanguage();
  const { frHomeStepsBlock, enHomeStepsBlock } = useStaticQuery(
    graphql`
      query {
        frHomeStepsBlock: contentfulHomeStepsBlock(node_locale: {eq: "fr"}) {
          image {
            secure_url
          }
          title
          description
          steps {
            line2
            line1
            image {
              secure_url
            }
            mobileImage {
              secure_url
            }
          }
          id
        }
        enHomeStepsBlock: contentfulHomeStepsBlock(node_locale: {eq: "en"}) {
          image {
            secure_url
          }
          title
          description
          steps {
            line2
            line1
            image {
              secure_url
            }
            mobileImage {
              secure_url
            }
          }
          id
        }   
      }
    `
  )
  const image = language === "fr" ? frHomeStepsBlock.image[0].secure_url : enHomeStepsBlock.image[0].secure_url;
  const title = language === "fr" ? frHomeStepsBlock.title : enHomeStepsBlock.title;
  const description = language === "fr" ? frHomeStepsBlock.description : enHomeStepsBlock.description;
  const steps = language === "fr" ? frHomeStepsBlock.steps : enHomeStepsBlock.steps;
  return (
    <Container className="several-steps-container">
      <Title>{title}</Title>
      <Text>{description}</Text>
      <StepsBlock>
        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
        {steps.map((step: any, i: number) => (
          <StepBlock key={i}>
            <StepImage>
              <img src={step.mobileImage[0].secure_url} style={{borderTopLeftRadius: 10, borderTopRightRadius: 10}} />
            </StepImage>
            <StepNumber>
              {i + 1}
            </StepNumber>
            <StepTitle>
              {step.line1}
            </StepTitle>
            <StepText>
              {step.line2}
            </StepText>
          </StepBlock>
        ))}
      </StepsBlock>
      <DesktopStepsContainer>
        <DesktopStepsBlock>
          {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
          {steps.map((step: any, i: number) => (
            <DesktopStep key={i}>
              <DesktopStepIcon>
                <img src={step.image[0].secure_url} />
              </DesktopStepIcon>
              <DesktopStepText>
                <DesktopStepTitle>
                  {step.line1}
                </DesktopStepTitle>
                <DesktopStepDescription>
                  {step.line2}
                </DesktopStepDescription>
              </DesktopStepText>
            </DesktopStep>
          ))}
        </DesktopStepsBlock>
        <DesktopStepsBlock>
          <img src={image} />
        </DesktopStepsBlock>
      </DesktopStepsContainer>
    </Container>
  )
}

export default HomeSteps
