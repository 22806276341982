import styled from "styled-components";

export const ButtonBlock = styled.div`
  width: 100%;
  font-family: ${({ theme }): string => theme.fonts.main};

  @media (min-width: 1000px) {
    width: 65%;
  }

  @media (min-width: 440px) {
    width: 405px;
  }

  @media (max-width: 314px) {
    margin-top: 20px;
  }

  @media (max-width: 274px) {
    margin-top: 40px;
  }
`;

export const InputBlock = styled.div`
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

export const InputContainer = styled.div`
  width: 100%;
  margin-left: 5%;

  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    width: 40%;
    margin-left: 80px;
  }
`;
