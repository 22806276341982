import React, { FC } from "react";
import "dayjs/locale/en";
import "dayjs/locale/fr";
import dayjs from "dayjs";

import { Input, InputSection } from "./style";
import { FormattedMessage } from "react-intl";
import usePickupDropOffRangePicker from "./use-pickup-drop-off-range-picker";
import Spinner from "../../images/spinner.svg";
import userLanguage from "../hooks/useLanguage";

interface Props {
  isLoading?: boolean;
  onClick: () => void;
  reschedule?: boolean;
}

const PickupRangeInput: FC<Props> = ({ isLoading = false, onClick, reschedule=false }) => {
  const {
    loading,
    onPickupPress,
    pickupRange,
  } = usePickupDropOffRangePicker(reschedule);

  const locale = userLanguage();

  if (loading || isLoading) {
    return <Spinner />;
  }
  return (
    <>
      <InputSection>
      <FormattedMessage id="pickup.pickdate" />
      <Input
          onMouseDown={(e): void => e.preventDefault()}
          onClick={(): void => { onPickupPress(); onClick(); }}
        >
          <option>
            {pickupRange &&
              dayjs(pickupRange.startDate).locale(locale).format("dddd D MMMM")
            }
          </option>
        </Input>
      </InputSection>
      <InputSection>
      <FormattedMessage id="pickup.picktime" />
        <Input
          onMouseDown={(e): void => e.preventDefault()}
          onClick={(): void => { onPickupPress(); onClick(); }}
        >
          <option>
            {pickupRange &&
              `${dayjs(pickupRange.startDate).format("HH:mm")} - ${dayjs(pickupRange.endDate).format("HH:mm")}`
            }
          </option>
        </Input>
      </InputSection>
    </>
  );
};

export default PickupRangeInput;
