import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme: { colors } }): string => colors.grey};
  padding-top: 30px;
`

export const Title = styled.h2`
  width: 90%;
  font-family: ${({ theme: { fonts } }): string => fonts.main };
  font-size: 20px;
  font-weight: bold;
  line-height: normal;
  text-align: center;
  color: ${({ theme: { colors } }): string => colors.primary};
  margin-bottom: 20px;
  margin-top: 35px;
`

export const Text = styled.span`
  max-width: 344px;
  width: 90%;
  height: 30px;
  font-family: ${({ theme: { fonts } }): string => fonts.main };
  font-size: 15px;
  font-weight: normal;
  line-height: normal;
  text-align: center;
  color: ${({ theme: { colors } }): string => colors.lightGrey};
  margin-bottom: 15px;

  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    max-width: 500px;
  }
`;

export const StepsBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    display: none;
  }
`;

export const DesktopStepsBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 440px;
  width: 600px;
`;

export const DesktopStep = styled.div`
  display: flex;
  flex-direction: row;
  height: 100px;
`;

export const DesktopStepIcon = styled.div`
  margin-right: 40px;
`;

export const DesktopStepText = styled.div`
  font-family: ${({ theme: { fonts } }): string => fonts.main };
`;

export const DesktopStepTitle = styled.div`
  font-weight: bold;
  font-family: ${({ theme: { fonts } }): string => fonts.main };
`;

export const DesktopStepDescription = styled.div`
  font-size: 14px;
  font-family: ${({ theme: { fonts } }): string => fonts.main };
`;

export const DesktopStepsContainer = styled.div`
  display: none;

  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
`;

export const StepBlock = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme: { colors } }): string => colors.white};
  border-radius: 10px;
  align-items: center;
  margin: 0 10px 30px 10px;
`

export const StepNumber = styled.div`
  width: 38px;
  height: 38px;
  color: ${({ theme: { colors } }): string => colors.white};
  background-color: ${({ theme: { colors } }): string => colors.secondary};
  font-family: ${({ theme: { fonts } }): string => fonts.main };
  border-radius: 30px;
  text-align: center;
  padding-top: 7px;
  margin-top: -50px;
  margin-bottom: 30px;
  z-index: 1;
`

export const StepText = styled.div`
  width: 209px;
  font-family: ${({ theme: { fonts } }): string => fonts.main };
  font-size: 14px;
  line-height: normal;
  text-align: center;
  color: ${({ theme: { colors } }): string => colors.primary};
  padding-bottom: 20px;
`

export const StepTitle = styled(StepText)`
  font-weight: bold;
`

export const StepImage = styled.div`
  width: 300px;
`
