import { graphql, useStaticQuery } from "gatsby";
import React, { useState, FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../../store";
import useThunkDispatch from "../../../hooks/use-thunk-dispatch";
import { getAddresses } from "../../../store/user/actions";
import { Order } from "../../../types/order";
import { Option } from "../../../types/option";
import { getOrders } from "../../../store/orders/actions";
import usePickupDropOffRangePicker from "../../pickup-drop-off-range-picker/use-pickup-drop-off-range-picker";
import PickupRangeModal from "../../pickup-drop-off-range-picker/modal";
import {
  setCartAddress,
  setCurrentOption,
  getOptions,
} from "../../../store/shopping-cart/actions";
import { HeaderContainer } from "../style/home-header/home-header-style";
import ConfirmAddressModal from "../../confirm-address-modal";
import {
  ExtendedGoogleMapsPlace,
  PARIS_COORD,
} from "../../../store/address/types";
import Inner, { HeaderProps } from "./Inner";
import userLanguage from "../../hooks/useLanguage";

export { Inner, HeaderProps };

const getUserLastOrderOption = (
  allOptions: Option[],
  lastOrder: Order | undefined
): Option | undefined => {
  if (allOptions.length > 0 && lastOrder) {
    return allOptions.find(option => option.name == lastOrder.serviceClass);
  }
  if (allOptions.length > 0) {
    return allOptions[0];
  }
  return undefined;
};

const HomeHeader: FC<HeaderProps> = props => {
  const dispatch = useThunkDispatch();

  const token = useSelector<AppState, string>(state => state.auth.token);
  const userAddress = useSelector<
    AppState,
    ExtendedGoogleMapsPlace | undefined
  >(state => {
    return token && state.user.addresses?.length > 0
      ? {
          coordinates: {
            lat: state.user.addresses[0].lat,
            lng: state.user.addresses[0].lng,
          },
          addressComponents: {
            ...state.user.addresses[0],
          },
          formattedAddress: `${state.user.addresses[0].number} ${state.user.addresses[0].street}, ${state.user.addresses[0].postalCode} ${state.user.addresses[0].city}, ${state.user.addresses[0].country}`,
        }
      : undefined;
  });
  const [address, setAddress] = useState<ExtendedGoogleMapsPlace>({
    coordinates: PARIS_COORD,
  });
  const userLastOrder = useSelector<AppState, Order | undefined>(
    state => state.orders.orders[0]
  );
  const [confirmAddressModalIsOpen, openAddressModal] = useState<boolean>(
    false
  );
  const allOptions = useSelector<AppState, Option[]>(
    state => state.shoppingCart.options
  );

  useEffect(() => {
    const userLastOrderOption = getUserLastOrderOption(
      allOptions,
      userLastOrder
    );
    if (userLastOrderOption) {
      dispatch(setCurrentOption(userLastOrderOption));
    }
  }, [allOptions, userLastOrder]);

  const {
    onPickupPress,
    ...pickupRangePickerModalProps
  } = usePickupDropOffRangePicker();

  useEffect(() => {
    const loadAddresses = async (): Promise<void> => {
      await dispatch(getAddresses());
    };
    const loadOrders = async (): Promise<void> => {
      await dispatch(getOrders(0));
    };
    const loadOrderOptions = async (): Promise<void> => {
      await dispatch(getOptions());
    };

    loadOrders();
    loadAddresses();
    loadOrderOptions();

    if (userAddress) {
      dispatch(setCartAddress(userAddress));
      setAddress(userAddress);
    }
  }, []);

  const language = userLanguage();

  const { frHomePage, enHomePage } = useStaticQuery(
    graphql`
      query {
        frHomePage: contentfulHomePage(node_locale: { eq: "fr" }) {
          id
          title
          titleMetadata
          backgroundImage {
            secure_url
          }
        }
        enHomePage: contentfulHomePage(node_locale: { eq: "en" }) {
          id
          title
          titleMetadata
          backgroundImage {
            secure_url
          }
        }
      }
    `
  );

  return (
    <>
      <ConfirmAddressModal
        open={confirmAddressModalIsOpen}
        closeModal={(): void => {
          openAddressModal(false);
          if (userAddress) {
            setAddress(userAddress);
            dispatch(setCartAddress(userAddress));
          }
        }}
        initialPlace={{ ...address }}
        onConfirmation={
          token !== ""
            ? // if user is logged in, we just close the modal because he can chose pickuprange from homepage
              (): void => openAddressModal(false)
            : // else, default comportment (on confirmation redirect to cart page)
              undefined
        }
        updateCartAddress
      />
      <PickupRangeModal {...pickupRangePickerModalProps} />
      <HeaderContainer className="home-header-image-container">
        <Inner
          setAddress={setAddress}
          openAddressModal={openAddressModal}
          openPickupRangeModal={onPickupPress}
          {...props}
          title={language === "fr" ? frHomePage.title : enHomePage.title}
          imageUrl={
            frHomePage.backgroundImage &&
            frHomePage.backgroundImage[0] &&
            frHomePage.backgroundImage[0].secure_url
          }
          // title={language === "fr" ? frHomePage.title : enHomePage.title}
          // imageUrl={
          //   language === "fr"
          //     ? frHomePage.backgroundImage &&
          //     frHomePage.backgroundImage[0] &&
          //     frHomePage.backgroundImage[0].secure_url
          //     : enHomePage.backgroundImage &&
          //     enHomePage.backgroundImage[0] &&
          //     enHomePage.backgroundImage[0].secure_url
          // }
          logged={token !== ""}
          initialAddress={address}
          isLoading={allOptions.length === 0}
        />
      </HeaderContainer>
    </>
  );
};

export default HomeHeader;
