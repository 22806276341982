import React, { FC, ReactChild } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import PickupRangeInput from "../../pickup-drop-off-range-picker/pickup-range-input";
import {
  DiscountContainer,
  DiscountTitle,
  DiscountCode,
  DiscountCondition,
  EmptyDiscountContainer,
} from "../style/home-header/discount";
import { HomeHeaderContainer } from "../style/home-header/home-header-style";
import {
  InputContainer,
  InputBlock,
  ButtonBlock,
} from "../style/home-header/input";
import {
  ServicesContainer,
  ServicesBlock,
  ServiceContainer,
  ServiceText,
  CheckImage,
} from "../style/home-header/services";
import { Title, TitleContainer, TitleDiv } from "../style/home-header/title";
import ButtonLink from "../../../ui/button-link";
import Check from "../../../images/Check.svg";
import AddressAutocomplete from "../../address-autocomplete";
import { ExtendedGoogleMapsPlace } from "../../../store/address/types";
import { StyledBackgroundImage } from './style';

export interface HeaderProps {
  breadcrumb?: ReactChild | null;
  button?: boolean;
  home: boolean;
  services: string[];
  title?: string;
}

interface InnerHeaderProps extends HeaderProps {
  corporate?: boolean;
  imageUrl?: string;
  initialAddress?: ExtendedGoogleMapsPlace;
  isLoading?: boolean;
  logged?: boolean;
  openAddressModal: (open: boolean) => void;
  openPickupRangeModal?: () => void;
  setAddress: (addr: ExtendedGoogleMapsPlace) => void;
}

const InnerHeader: FC<InnerHeaderProps> = ({
  breadcrumb,
  button,
  corporate = false,
  home,
  imageUrl,
  initialAddress,
  isLoading = false,
  logged = false,
  openAddressModal,
  openPickupRangeModal,
  services,
  setAddress,
  title,
}): JSX.Element => {
  const intl = useIntl();

  return (
    <StyledBackgroundImage image={imageUrl ? imageUrl : ""}>
      <HomeHeaderContainer>
        {home && (
          <>
            <DiscountContainer>
              <DiscountTitle>
                <FormattedMessage id="home.discount.title" />
              </DiscountTitle>
              <DiscountCode>
                <FormattedMessage id="home.discount.code" />
              </DiscountCode>
            </DiscountContainer>
            <DiscountCondition>
              <FormattedMessage id="home.discount.condition" />
            </DiscountCondition>
          </>
        )}
        {!home && !breadcrumb && <EmptyDiscountContainer />}
        {breadcrumb}
        <TitleContainer>
          <TitleDiv>
            <Title corporate={corporate} className="header-title">
              {title ? (
                title
              ) : corporate ? (
                <FormattedMessage id="company.header.title" />
              ) : (
                <FormattedMessage id="home.title" />
              )}
            </Title>
          </TitleDiv>
        </TitleContainer>
        <InputContainer>
          <InputBlock>
            {button ? (
              <ButtonBlock>
                <ButtonLink
                  link="#form"
                  text={intl.formatMessage({
                    id: "company.header.button",
                  })}
                  borderStyle="30px"
                  width="100%"
                />
              </ButtonBlock>
            ) : (
              <AddressAutocomplete
                inputId="address-autocomplete"
                placeholder={intl.formatMessage({
                  id: "home.google.autocomplete.placeholder",
                })}
                initialValue={initialAddress}
                onPlaceChange={(newAddress): void => {
                  setAddress(newAddress);
                  openAddressModal(true);
                }}
                border
                highlighted
                leftIcon
                cta
              />
            )}
          </InputBlock>
          {logged && initialAddress && (
            <InputBlock>
              <PickupRangeInput
                isLoading={isLoading}
                onClick={(): void => { if (openPickupRangeModal) openPickupRangeModal(); }}
                reschedule
              />
            </InputBlock>
          )}
        </InputContainer>
        <ServicesBlock>
          <ServicesContainer>
            {services.map(service => (
              <ServiceContainer key={service}>
                <CheckImage>
                  <Check />
                </CheckImage>
                <ServiceText>
                  <FormattedMessage id={service} />
                </ServiceText>
              </ServiceContainer>
            ))}
          </ServicesContainer>
        </ServicesBlock>
      </HomeHeaderContainer>
    </StyledBackgroundImage>
  );
};

export default InnerHeader;
