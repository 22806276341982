import styled from "styled-components";

export const ServicesBlock = styled.div`
  display: flex;
  justify-content: center;

  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    justify-content: left;
  }
`;

export const ServicesContainer = styled.div`
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  padding-top: 20px;

  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    width: 40%;
    margin-left: 80px;
  }
`;

export const ServiceContainer = styled.span`
  height: 25px;
  border-radius: 12.5px;
  padding: 9px 9px 8px 4px;
  background-color: ${({ theme: { colors } }): string => colors.white};
  display: flex;
  align-items: center;
  margin-right: 15px;
  margin-bottom: 15px;
`;

export const CheckImage = styled.span`
  width: 20px;
  height: 20px;
  background-color: ${({ theme: { colors } }): string => colors.secondary};
  color: ${({ theme: { colors } }): string => colors.white};
  margin-right: 10px;
  border-radius: 10px;
  justify-content: center;
  display: flex;
  align-items: center;
`;

export const ServiceText = styled.span`
  height: 13px;
  font-family: ${({ theme: { fonts } }): string => fonts.main};
  font-size: 10px;
  font-weight: 500;
  line-height: normal;
  color: ${({ theme: { colors } }): string => colors.primary};
`;
