import styled from "styled-components";

export const StyledBackgroundImage = styled.div<{ image: string }>`
  height: 100%;
  padding-top: 50px;
  opacity: unset;
  background-image: url(${(props): string => (props.image ? props.image : "")});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    background-position: top;
    background-attachment: fixed;
    clip-path: ellipse(100% 92% at 50% 8%);
    padding-top: 170px;
  }
`;
