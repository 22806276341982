import styled from 'styled-components';

export const TitleContainer = styled.div`
  width: 100%;
`

export const TitleDiv = styled.div`
  width: 90%;
  margin: auto;
`

interface TitleProp {
  corporate?: boolean;
}

export const Title = styled.h1<TitleProp>`
  max-width: 346px;
  height: 102px;
  font-family: ${({ theme: { fonts } }): string => fonts.main };
  font-size: 28px;
  font-weight: bold;
  line-height: normal;
  color: ${({ corporate, theme: { colors } }): string => corporate ? colors.white : colors.primary};
  margin-top: 50px;

  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    font-size: 38px;
    height: 150px;
    max-width: 500px;
  }
`
