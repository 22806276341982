import styled from 'styled-components';

export const HeaderContainer = styled.div`
  height: 500px;
  background-color: #f5f5f5;

  @media (min-width: 440px) {
    height: 800px;
  }
`;

export const HomeHeaderContainer = styled.div`
  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    padding-left: 150px;
  }
`;
