import styled from 'styled-components';

export const EmptyDiscountContainer = styled.div`
  height: 54px;
`

export const DiscountContainer = styled.div`
  width: 180px;
  height: 44px;
  border-radius: 0 4px 4px 0;
  background-color: ${({ theme: { colors } }): string => colors.primary};
  color: ${({ theme: { colors } }): string => colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    margin-left: 80px;
  }
`

export const DiscountTitle = styled.span`
  width: 145px;
  height: 22px;
  font-family: ${({ theme: { fonts } }): string => fonts.main };
  font-size: 18px;
  font-weight: bold;
  line-height: normal;
  text-align: center;
  color: ${({ theme: { colors } }): string => colors.white};
`

export const DiscountCode = styled.span`
  width: 143px;
  height: 10px;
  font-family: ${({ theme: { fonts } }): string => fonts.main };
  font-size: 8px;
  font-weight: bold;
  line-height: normal;
  text-align: center;
  color: ${({ theme: { colors } }): string => colors.white};
`

export const DiscountCondition = styled.span`
  width: 134px;
  height: 10px;
  font-family: ${({ theme: { fonts } }): string => fonts.main };
  font-size: 10px;
  font-weight: normal;
  line-height: normal;
  text-align: right;
  color: ${({ theme: { colors } }): string => colors.primary};
  padding-left: 20px;

  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    margin-left: 80px;
    padding-left: 0;
  }
`
